import { useStaticQuery, graphql } from "gatsby"

import { reformatAirtableData } from "../services/reformatAirtableData"

const ALL_FLEXMED_PRODUCTS = graphql`
  query {
    allAirtableallMaerskProducts(
      filter: { data: { ShowOnWebsite: { eq: true } } }
    ) {
      nodes {
        data {
          brand: Brand
          molecule: Molecule
          productTitle: ProductTitle
          rxRequired: RxRequired
          rxLevel: DrugRxLevel
          classification: Classification
          priceList: PriceList
          vatexUnitPrice: VatexUnitPrice
          vat: Vat
          drugName: DrugName
          form: Form
          skuTags: SKUTags
          strength: Strength
        }
      }
    }
  }
`

export const useFlexMedProducts = () => {
  const QUERY = useStaticQuery(ALL_FLEXMED_PRODUCTS)
  let products = QUERY?.allAirtableallMaerskProducts?.nodes
  products = products?.map(product => reformatAirtableData(product.data))

  return products
}
