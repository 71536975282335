import React from "react"
import RxIndicator from "../../Elements/RxIndicator"
import { generatePrice } from "../../Epharmacy/services/computations"

const SearchResultRow = ({ result, handleClick }) => {
  return (
    <li onClick={() => handleClick()}>
      <div className="is-flex has-text-weight-bold">
        <div>{result.productTitle}</div>
        {result.rxRequired && <RxIndicator />}
      </div>
      {!!parseInt(generatePrice(result)) && (
        <div className="is-size-7 mt-1">Php {generatePrice(result)}</div>
      )}
    </li>
  )
}

export default SearchResultRow
