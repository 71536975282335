import React, { Fragment, useState, useEffect } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import SearchResultRow from "./SearchResults/SearchResultRow"
import styles from "./utils/search.module.scss"

const SearchResults = ({
  searchResults,
  isExactMatch,
  onSelect,
  setSearchResults,
}) => {
  return (
    <section className={classNames(styles["searchResult"])}>
      <ol className={classNames({ "is-hidden": !searchResults.length })}>
        {searchResults.length > 0 && isExactMatch && (
          <Fragment>
            <li className={classNames(styles["errorMessage"], "is-size-7")}>
              <FontAwesomeIcon icon={faExclamationCircle} />{" "}
              <b>The item you searched for cannot be found.</b> Please type the
              item again or check if an 'Other Brand' option is available.
            </li>
          </Fragment>
        )}
        {searchResults.length > 0 && !isExactMatch && (
          <Fragment>
            <li className={classNames(styles["promptMessage"], "is-size-7")}>
              <FontAwesomeIcon icon={faExclamationCircle} />{" "}
              <b>Please select from the dropdown for faster processing</b>
            </li>
          </Fragment>
        )}
        {searchResults.map(result => (
          <SearchResultRow
            result={result}
            handleClick={() => {
              if (onSelect) onSelect(result)
              setSearchResults([])
            }}
          />
        ))}
        {searchResults.length > 0 && isExactMatch && (
          <Fragment>
            <li className={classNames(styles["errorMessage"], "is-size-7")}>
              <FontAwesomeIcon icon={faExclamationCircle} />{" "}
              <b> Still can't find it? </b> Please visit our{" "}
              <a
                href="/help-center#other-medicines"
                target="_blank"
                className={classNames(styles["errorMessage"], "has-text")}
              >
                <b>help-center</b>
              </a>
              .
            </li>
          </Fragment>
        )}
      </ol>
    </section>
  )
}

export default SearchResults
