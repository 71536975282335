import React from "react"
import Layout from "../../components/Layout/Layout"
import Order from "../../components/Epharmacy/Order"

export default () => (
  <Layout
    title="Order Medicines"
    subtitle="Enter your medicines to begin ordering."
    process="epharmacy"
    seoTitle="Order Medicines"
  >
    <Order nextRoute="/epharmacy/upload" backRoute="/epharmacy/mechanics" />
  </Layout>
)
