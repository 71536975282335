export const reformatAirtableData = dataMapElement => {
  let jsonFormat = {
    brand: dataMapElement?.brand?.[0],
    drugName: dataMapElement?.drugName?.[0],
    form: dataMapElement?.form?.[0],
    molecule: dataMapElement?.molecule?.[0],
    rxRequired: dataMapElement?.rxRequired?.[0],
    productTitle: dataMapElement?.productTitle?.[0],
    classification: dataMapElement?.classification?.[0],
    rxLevel: dataMapElement?.rxLevel?.[0],
    strength: dataMapElement?.strength?.[0],
    skuTags: dataMapElement?.skuTags,
    price_list: {
      vatex_unit_price: dataMapElement?.vatexUnitPrice,
      vat: dataMapElement?.vat,
    },
  }

  return jsonFormat
}
