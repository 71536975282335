import React, { useState, useEffect, Fragment } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { fuzzySearch } from "./services/fuzzySearch"
import SearchResults from "./SearchResults"

const Search = ({
  label,
  isRequired,
  type,
  name,
  placeholder,
  dataSource,
  onSelect,
  handleChange,
  disabled,
  field = {},
  withIcon,
}) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [searchQueue, setSearchQueue] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOptionHidden, setOptionHidden] = useState(true)

  useEffect(() => {
    setSearchResults([])
    if (searchQuery) {
      setIsLoading(true)
      switch (dataSource.type) {
        case "api":
          break
        case "graphql":
          if (searchQueue) clearTimeout(searchQueue)
          setSearchQueue(
            setTimeout(async () => {
              let allResults = await fuzzySearch(
                searchQuery,
                dataSource.data,
                dataSource.lookupKeys
              )
              let topSearchResults = allResults.slice(0, 10)
              let isOtherMedicines = topSearchResults.some(
                result => result.productTitle === "Other Medicines"
              )
              let otherMedicinesindex = topSearchResults.findIndex(
                result => result.productTitle === "Other Medicines"
              )
              if (isOtherMedicines) {
                let otherMedicine = topSearchResults.splice(
                  otherMedicinesindex,
                  1
                )[0]
                if (searchQuery.toLowerCase().trim() == "other medicines")
                  topSearchResults.push(otherMedicine)
              }
              setSearchResults(topSearchResults)
              setIsLoading(false)
            }, 1000)
          )

          break
        default:
          break
      }
    }
  }, [searchQuery])

  const handleValidateMatchDistance = () => {
    return searchResults.length > 0 && searchResults[0].distance !== 1
  }

  const handleOnKeyDown = event => {
    if (event.key === "Enter" || event.key == "Tab") setOptionHidden(true)
  }

  const handleOnBlur = event => setTimeout(() => setOptionHidden(true), 200)

  return (
    <Fragment>
      <div>
        {!!label && (
          <label
            className={classNames("label", {
              "has-text-weight-normal has-text-grey-dark": !isRequired,
            })}
          >
            {label}
            {!!isRequired && <span className="has-text-danger"> *</span>}
          </label>
        )}
        <div className="field-body">
          <div className="field">
            <p
              className={classNames("control", {
                "is-loading": isLoading,
                " has-icons-left": withIcon,
              })}
            >
              <input
                isRequired
                type={type}
                className="input"
                name={name}
                placeholder={placeholder}
                {...field}
                onChange={event => {
                  if (field.onChange) field.onChange(event)
                  if (isOptionHidden) setOptionHidden(false)
                  setSearchQuery(event.target.value)
                  handleChange()
                }}
                onKeyDown={handleOnKeyDown}
                onBlur={handleOnBlur}
                disabled={disabled}
              />
              {withIcon && (
                <span className={classNames("icon is-small is-left")}>
                  <FontAwesomeIcon icon={faSearch} />
                  {/* )} */}
                </span>
              )}
              {/* <p> This is where you display error message </p> */}
            </p>
          </div>
        </div>
      </div>
      {!isOptionHidden && (
        <SearchResults
          searchResults={searchResults}
          isExactMatch={handleValidateMatchDistance()}
          onSelect={onSelect}
          setSearchResults={setSearchResults}
        />
      )}
    </Fragment>
  )
}

export default Search
