import React, { useContext, Fragment } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"

import CartLayout from "../Layout/CartLayout"
import Cart from "./Cart"
import Search from "../Search/Search"
import ConfirmNavigationModal from "../Elements/ConfirmNavigationModal"

import { AppContext } from "../../context/AppContext"

import { useFlexMedProducts } from "./hooks/useFlexMedProducts"

const Order = ({ backRoute, nextRoute }) => {
  const data = useFlexMedProducts()
  const { dispatch, state } = useContext(AppContext)

  const handleOnSelect = async item => {
    const medicineList = [...state.epharmacy.medicines]
    if (!item.molecule) medicineList.push({ ...item, qty: 1 })
    else medicineList.push({ ...item, qty: 10 })
    await dispatch({ type: "SAVE_MEDICINES", payload: medicineList })
  }

  const displayNavigationModal = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Cancel your request",
        isCard: true,
        headerClass: "is-size-5",
        content: <ConfirmNavigationModal backRoute={backRoute} />,
      },
    })
  }

  const checkIfMedicineQuantitiesAreEmpty = medicines => {
    let emptyQuantities = medicines.filter(
      medicine => medicine.qty < 1 || !medicine.qty
    )

    return emptyQuantities.length > 0
  }

  return (
    <CartLayout
      cartContainer={<Cart open={{ medicinesCard: true }} />}
      next={{
        label: "Next",
        link: nextRoute,
        disabled:
          state.epharmacy.medicines.length === 0 ||
          checkIfMedicineQuantitiesAreEmpty(state.epharmacy.medicines),
      }}
      back={{
        label: "Cancel",
        link: backRoute,
        callback: displayNavigationModal,
      }}
      desktop={10}
      isCentered
    >
      <Search
        label="Medicine Search"
        dataSource={{
          type: "graphql",
          data: data,
          lookupKeys: ["molecule", "brand"],
        }}
        onSelect={handleOnSelect}
        placeholder="Metformin"
      />
    </CartLayout>
  )
}

export default Order
